import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import config from "../../config"; // Asegúrate de que esta ruta sea correcta
import Seo from "../../components/seo";
import ContentPageLayout from "../../components/ContentPageLayout";
import ContentSkeleton from "../../components/ContentSkeleton";
import BackButton from "../../components/buttons/BackButton";
import { FaSearch, FaTimes } from "react-icons/fa";

const Coach = () => {
  const [coaches, setCoaches] = useState([]);
  const [filteredCoaches, setFilteredCoaches] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [coachBanner, setCoachBanner] = useState(null);
  const [imageHeight, setImageHeight] = useState("auto");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Fetch coaches
        const coachesResponse = await fetch(`${config.serverUri}/coaches`);
        const coachesData = await coachesResponse.json();
        setCoaches(coachesData);
        setFilteredCoaches(coachesData);

        // Fetch banner
        const bannerResponse = await fetch(`${config.serverUri}/banner-de-coaches`);
        const bannersData = await bannerResponse.json();
        setCoachBanner(bannersData[0] || null);
        console.log(bannersData[0])
        // Set image height based on banner dimensions
        if (bannersData[0]?.desktopImage?.width && bannersData[0]?.desktopImage?.height) {
          const aspectRatio = bannersData[0].desktopImage.height / bannersData[0].desktopImage.width;
          setImageHeight(window.innerWidth * aspectRatio);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
    setSearchText("");
    setFilteredCoaches(coaches); // Reset filtered coaches
  };

  const handleSearch = () => {
    if (!searchText.trim()) {
      setFilteredCoaches(coaches);
      return;
    }

    const lowerCaseSearchText = searchText.toLowerCase();
    const results = coaches.filter((coach) =>
      coach.nombre.toLowerCase().includes(lowerCaseSearchText)
    );
    setFilteredCoaches(results);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const getImageUrl = (url) =>
    url?.startsWith("http") ? url : `${config.serverUri}${url}`;

  return (
    <ContentPageLayout>
      <Seo title="Coach" />
      {isLoading ? (
        <ContentSkeleton />
      ) : (
        <div>
          {/* Search Bar */}
          <div className="flex p-4">
            <BackButton fontSize={30} route="/" />
            <div className="ml-auto flex items-center justify-end">
              {!searchVisible ? (
                <FaSearch
                  fontSize={18}
                  className="cursor-pointer"
                  onClick={() => setSearchVisible(true)}
                />
              ) : (
                <>
                  <input
                    type="text"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Buscar..."
                    className="bg-transparent border-b focus:outline-none pr-4"
                  />
                  <FaTimes
                    fontSize={18}
                    className="cursor-pointer ml-2"
                    onClick={toggleSearch}
                  />
                </>
              )}
            </div>
          </div>

          {/* Banner */}
          {coachBanner && (
            <div
              className="relative bg-white"
              style={{
                width: "100%",
                height: `${imageHeight}px`,
              }}
            >
              <img
                src={getImageUrl(coachBanner.desktopImage.url)}
                alt="Coach Banner"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )}

          {/* Coaches */}
          <main className="p-8 mb-32">
            {!filteredCoaches.length ? (
              <p>No hay coaches que coincidan con la búsqueda.</p>
            ) : (
              <div className="flex flex-wrap justify-start">
                {filteredCoaches.map((coach) => {
                  const imageUrl = getImageUrl(coach.fotoPerfil?.url);
                  return (
                    <div
                      key={coach._id}
                      className="w-40 m-2 text-center cursor-pointer"
                      onClick={() => navigate(`/coaches/${coach._id}`)}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt={coach.nombre}
                          className="w-32 h-32 rounded-full object-cover mx-auto mb-2"
                        />
                      ) : (
                        <div
                          className="w-32 h-32 rounded-full bg-gray-300 flex items-center justify-center mx-auto mb-2"
                        >
                          <span className="text-sm font-medium text-gray-700">
                            {coach.nombre.split(" ").slice(0, 2).join(" ")}
                          </span>
                        </div>
                      )}
                      <p className="text-sm font-medium">{coach.nombre}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </main>
        </div>
      )}
    </ContentPageLayout>
  );
};

export default Coach;
